<template>
  <CFooter>
    <div>
      <a href="https://coreui.io" target="_blank">CoreUI</a>
      <span class="ms-1">&copy; {{ new Date().getFullYear() }} creativeLabs.</span>
    </div>
    <div class="ms-auto">
      <a :href="ssoServer" target="_blank">SSO Server</a>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'AppFooter',
  data() {
    return {
      ssoServer: process.env.VUE_APP_SSO_URL,
    }
  },
}
</script>

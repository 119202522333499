import ajax from '@/helpers/ajax'
import { getLocalStorage, setLocalStorage } from '@/helpers/utils'

export const unit = {
  namespaced: true,
  state: {
    units: [],
  },
  mutations: {
    updateUnits(state, units) {
      state.units = units
      setLocalStorage('units', units)
    },
  },
  actions: {
    getUnits({ commit, state }) {
      if (state.units.length > 0) {
        return
      }

      const units = getLocalStorage('units')
      if (units && units.length > 0) {
        return commit('updateUnits', units)
      }

      ajax.get('/v1/units').then((response) => {
        const data = response.data.data
        commit('updateUnits', data)
      })
    },
  },
}

export const sidebar = {
  strict: true,
  namespaced: true,
  state: {
    sidebarVisible: true,
    sidebarUnfoldable: false,
    topMenuVisible: true,
    leftMenuVisible: true,
  },
  mutations: {
    toggleSidebar(state) {
      state.sidebarVisible = !state.sidebarVisible
    },
    toggleUnfoldable(state) {
      state.sidebarUnfoldable = !state.sidebarUnfoldable
    },
    updateSidebarVisible(state, payload) {
      state.sidebarVisible = payload.value
    },
    hideTopMenu(state, isHide = true) {
      state.topMenuVisible = !isHide
    },
    hideSidebar(state, isHide = true) {
      state.sidebarVisible = !isHide
    },
    showTopMenu(state) {
      state.topMenuVisible = true
    },
    showSidebar(state) {
      state.sidebarVisible = true
    },
  },
}

<template>
  <CHeader position="sticky" class="mb-4" v-show="!topMenuVisible"></CHeader>
  <CHeader position="sticky" class="mb-4" v-show="topMenuVisible">
    <CContainer fluid>
      <CHeaderToggler class="ps-1" @click="$store.commit('sidebar/toggleSidebar')">
        <CIcon icon="cil-menu" size="lg" />
      </CHeaderToggler>
      <CHeaderBrand class="mx-auto d-lg-none" to="/">
        <img class="sidebar-brand-full" :src="logo" :title="title" :alt="logo" style="height: 35px" />
      </CHeaderBrand>

      <CHeaderNav v-show="leftMenuVisible">
        <AppHeaderDropdownAccount />
      </CHeaderNav>
    </CContainer>
    <CHeaderDivider />
    <CContainer fluid>
      <AppBreadcrumb />
    </CContainer>
  </CHeader>
</template>

<script>
import AppBreadcrumb from './AppBreadcrumb'
import AppHeaderDropdownAccount from './AppHeaderDropdownAccnt'
import { useStore } from 'vuex'
import { computed } from 'vue'
import logo from '@/assets/brand/logo.jpg'

export default {
  name: 'AppHeader',
  components: {
    AppBreadcrumb,
    AppHeaderDropdownAccount: AppHeaderDropdownAccount,
  },
  setup() {
    const store = useStore()
    const title = process.env.VUE_APP_TITLE
    return {
      title,
      logo,
      topMenuVisible: computed(() => store.state.sidebar.topMenuVisible),
      leftMenuVisible: computed(() => store.state.sidebar.leftMenuVisible),
    }
  },
}
</script>

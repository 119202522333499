import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import CoreuiVue from '@coreui/vue'
import CIcon from '@coreui/icons-vue'
import { iconsSet as icons } from '@/assets/icons'
import DocsExample from '@/components/DocsExample'
import ElementPlus from 'element-plus'
import ajax from '@/helpers/ajax'

const debounce = (fn, delay) => {
  let timer = null
  return function () {
    let content = this
    let args = arguments
    clearTimeout(timer)
    timer = setTimeout(() => {
      fn.apply(content, args)
    }, delay)
  }
}

const _ResizeObserver = window.ResizeObserver
window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
  constructor(callback) {
    callback = debounce(callback, 16)
    super(callback)
  }
}

const app = createApp(App)
app.use(store)
app.use(router)
app.use(CoreuiVue)
app.use(ElementPlus)
app.provide('icons', icons)
app.component('CIcon', CIcon)
app.component('DocsExample', DocsExample)

app.config.globalProperties.$axios = ajax
app.mount('#app')

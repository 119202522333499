import ajax from '@/helpers/ajax'

export const menu = {
  strict: true,
  namespaced: true,
  state: {
    menus: [],
  },
  mutations: {
    updateMenus(state, records) {
      state.menus = records
    },
  },
  actions: {
    async getMenus({ commit, state }) {
      return await ajax
        .get('/v1/transfer-demands', {
          params: {
            sortBy: state.sortBy,
            sortDesc: state.sortDesc,
            currentPage: state.currentPage,
            perPage: state.perPage,
          },
        })
        .then((response) => {
          const data = response.data.data
          commit('updateMenus', data.records)
          return state.menus
        })
    },
  },
}

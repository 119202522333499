export default [
  {
    component: 'CNavItem',
    name: 'Dashboard',
    to: '/dashboard',
    icon: 'cil-speedometer',
    badge: {
      color: 'primary',
    },
  },
  {
    component: 'CNavItem',
    name: '轉讓申請列表',
    icon: 'cil-puzzle',
    to: '/transfer/index',
  },
  {
    component: 'CNavItem',
    name: '轉讓申請',
    to: '/transfer/request',
    icon: 'cil-puzzle',
  },

  {
    component: 'CNavItem',
    name: '稽查申請列表',
    to: '/audit/index',
    icon: 'cil-puzzle',
  },
]

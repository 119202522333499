<template>
  <CDropdown variant="nav-item">
    <CDropdownToggle placement="bottom-end" class="py-0" :caret="true">
      <CAvatar :src="avatar" size="md" />
    </CDropdownToggle>
    <CDropdownMenu class="pt-0">
      <CDropdownHeader component="h6" class="bg-light fw-semibold py-2">{{ pharmacyName }}</CDropdownHeader>
      <CDropdownItem>
        <CIcon icon="cil-user" />
        {{ userName }} 您好
      </CDropdownItem>
      <CDropdownHeader component="h6" class="bg-light fw-semibold py-2"> 設定</CDropdownHeader>
      <CDropdownItem @click="redirectToProfile()">
        <CIcon icon="cil-user-follow" />
        帳號資料
      </CDropdownItem>
      <CDropdownDivider />
      <CDropdownItem @click="doLogout">
        <CIcon icon="cil-lock-locked" />
        登出
      </CDropdownItem>
    </CDropdownMenu>
  </CDropdown>
</template>

<script>
import avatar1 from '@/assets/images/avatars/1.jpg'
import avatar2 from '@/assets/images/avatars/2.jpg'
import avatar3 from '@/assets/images/avatars/3.jpg'
import avatar4 from '@/assets/images/avatars/4.jpg'
import avatar5 from '@/assets/images/avatars/5.jpg'
import avatar6 from '@/assets/images/avatars/6.jpg'
import avatar7 from '@/assets/images/avatars/7.jpg'
import avatar8 from '@/assets/images/avatars/8.jpg'
import avatar9 from '@/assets/images/avatars/9.jpg'
import router from '@/router'
import { getLocalStorage } from '@/helpers/utils'

export default {
  name: 'AppHeaderDropdownAccount',
  setup() {
    const avatars = [avatar1, avatar2, avatar3, avatar4, avatar5, avatar6, avatar7, avatar8, avatar9]
    const userInfo = getLocalStorage('user_info')
    const userId = parseInt(getLocalStorage('user_id'))
    const index = userId % avatars.length

    return {
      avatar: avatars[index],
      itemsCount: 0,
      userName: userInfo.userName,
      pharmacyName: userInfo.pharmacyName,
    }
  },
  methods: {
    doLogout() {
      this.$axios.get(`${process.env.VUE_APP_API_URL}/sso/logout`).finally(() => {
        localStorage.removeItem('access_token')
        localStorage.removeItem('user_info')
        window.location.href = '/'
      })
    },
    redirect(page) {
      router.push(`/${page}`)
    },
    redirectToProfile() {
      window.location.href = `${process.env.VUE_APP_SSO_URL}/profile`
    },
  },
  mounted() {
    ;[
      'ul.header-nav > li.nav-item',
      'a.nav-link',
      'a.nav-link > svg.icon',
      'div.dropdown',
      'div.dropdown-menu',
    ].forEach((selector) => {
      document.querySelectorAll(selector).forEach((item) => {
        item.addEventListener('click', function (event) {
          event.preventDefault()
          return false
        })
      })
    })
  },
}
</script>

import ajax from '@/helpers/ajax'

export const transferIndex = {
  namespaced: true,
  state: {
    items: [],
    isBusy: false,
    sortBy: 'id',
    sortDesc: true,
    currentPage: 1,
    total: 1,
    perPage: 10,
  },
  getters: {},
  mutations: {
    updateRecords(state, records) {
      state.items = records
    },
    updateTotal(state, total) {
      state.total = total
    },
    perPageChange(state, perPage) {
      state.perPage = perPage
      this.dispatch('transferIndex/getIndex')
    },
    handleSortChange(state, sortBy) {
      state.sortBy = sortBy.prop
      state.sortDesc = sortBy.order === 'ascending'
      this.dispatch('transferIndex/getIndex')
    },
    handleSizeChange() {
      this.dispatch('transferIndex/getIndex')
    },
    handleCurrentChange(state, target) {
      state.currentPage = target
      this.dispatch('transferIndex/getIndex')
    },
    busy(state, isBusy = true) {
      state.isBusy = isBusy
    },
  },
  actions: {
    getIndex({ commit, state }) {
      commit('busy')
      console.log(state.isBusy)
      ajax
        .get('/v1/transfer-demands', {
          params: {
            sortBy: state.sortBy,
            sortDesc: state.sortDesc,
            currentPage: state.currentPage,
            perPage: state.perPage,
          },
        })
        .then((response) => {
          const data = response.data.data
          commit('updateRecords', data.records)
          commit('updateTotal', parseInt(data.total))
        })
        .finally(() => {
          commit('busy', false)
          console.log(state.isBusy)
        })
    },
  },
}

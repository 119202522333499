import { createStore } from 'vuex'
import { menu } from '@/store/modules/Menu'
import { transferIndex } from '@/store/modules/TransferIndex'
import { sidebar } from '@/store/modules/Sidebar'
import { unit } from '@/store/modules/Unit'
import { auditIndex } from '@/store/modules/AuditIndex'

export default createStore({
  name: 'root',
  strict: true,
  namespaced: true,
  modules: {
    menu,
    sidebar,
    transferIndex,
    auditIndex,
    unit,
  },
})

import axios from 'axios'
import router from '@/router'
import { userService } from '@/helpers/user.service'
import { getLocalStorage, refreshToken } from '@/helpers/utils'
import { ElMessageBox } from 'element-plus'

const ajax = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
    get: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
    },
    post: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
    },
    put: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
    },
  },
  validateStatus: function () {
    return true
  },
  timeout: 40000,
})

// 根据不同的状态码，生成不同的提示信息
const showStatus = (status) => {
  let message
  switch (status) {
    case 400:
      message = '錯誤請求(400)'
      break
    case 401:
      message = '未登入或登入過久，請重新登入'
      break
    case 403:
      message = '禁止使用'
      break
    case 404:
      message = '找不到'
      break
    case 408:
      message = '請求超時'
      break
    case 500:
      message = '伺服務錯誤'
      break
    case 501:
      message = '無法回應'
      break
    case 502:
      message = '網路錯誤'
      break
    case 503:
      message = '不可使用'
      break
    case 504:
      message = '網路超時'
      break
    case 505:
      message = 'HTTP 版本不支援'
      break
    default:
      message = `API 請求錯誤 (${status})!`
  }
  return `${message}，如仍有問題，請檢查網路或聯絡系統管理員!`
}

//响应拦截器
ajax.interceptors.response.use(
  (response) => {
    const status = response.status
    if (status === 401) {
      alert(this.$router.options.history.state.back)
      userService.logout()
      router.push('/login')
    }

    if (status < 200 || status >= 300) {
      const msg = response.message || showStatus(status)
      if (typeof response.data === 'string') {
        response.data = { msg }
      } else {
        response.data.msg = msg
      }

      ElMessageBox.alert(msg, `error ${status}`)
      throw msg
    }

    return response
  },
  (error) => {
    return Promise.reject(error)
  },
)

// request interceptor
ajax.interceptors.request.use(
  async (request) => {
    const token = getLocalStorage('access_token')
    if (!token) {
      throw new axios.Cancel('No token')
    }

    refreshToken()

    request.headers['Authorization'] = `Bearer ${token}`
    return request
  },
  (error) => {
    // Do something with request error
    console.debug(error) // for debug
    return Promise.reject(error)
  },
)

export default ajax

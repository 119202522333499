import { setLocalStorage } from '@/helpers/utils'

export const userService = {
  login,
  logout,
}

function login(username, password) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ username, password }),
  }

  return fetch(`${process.env.VUE_APP_API_URL}/users/authenticate`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      if (!response.code || response.code !== 1) {
        alert('error: can not login!')
        console.error(response)
        return response
      }

      const result = response.data
      localStorage.setItem(
        'user',
        JSON.stringify({
          id: result.id,
          name: result.username,
          token: result.token, // 確認要用這一行，還是下面的 setItem
        }),
      )

      setLocalStorage('access_token', result.token)
      return result
    })
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem('user_info')
  localStorage.removeItem('access_token')
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text)
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout()
        location.reload()
      }

      const error = (data && data.message) || response.statusText
      return Promise.reject(error)
    }

    return data
  })
}

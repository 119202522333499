import ajax from '@/helpers/ajax'

export function random(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

export function isJson(str) {
  if (typeof str === 'string') {
    try {
      JSON.parse(str)
      return str.indexOf('{') > -1
    } catch (e) {
      return false
    }
  }
  return false
}

export function handleHttpResponse(response) {
  if (!response || typeof response === 'undefined') {
    console.debug('http response is undefined')
    alert('操作失敗')
    return 'http response is undefined'
  }

  if (response.status === 401) {
    return `(${response.status}) ${response.data.message || response.data.msg}.`
  }

  if (response.status < 200 || response.status >= 300) {
    alert('操作失敗' + '\n' + response.data.message || response.data.msg)
    console.debug(response)
    return `(${response.status}) ${response.data.message || response.data.msg}.`
  }

  if (response.data.code === 0) {
    console.debug(response)
    alert('操作失敗' + '\n' + response.data.message || response.data.msg)
    return "http response's data's code is 0"
  }

  return ''
}

export const setLocalStorage = (keyName, keyValue, ttl) => {
  if (!ttl) {
    ttl = (parseInt(process.env.VUE_APP_TOKEN_TTL) || 60) * 60
  }

  const data = {
    value: keyValue, // store the value within this object
    ttl: new Date(new Date().getTime() + 1000 * ttl), // store the TTL (time to live)
  }

  // store data in LocalStorage
  localStorage.setItem(keyName, JSON.stringify(data))
}

export const refreshToken = (ttl) => {
  const keyName = 'access_token'
  setLocalStorage(keyName, getLocalStorage(keyName), ttl)
}

export const getLocalStorage = (keyName) => {
  const data = localStorage.getItem(keyName)
  if (!data) {
    // if no value exists associated with the key, return null
    return null
  }

  if (!isJson(data)) {
    return data
  }

  const item = JSON.parse(data)
  const now = new Date()

  // If TTL has expired, remove the item from localStorage and return null
  if (now.getTime() > new Date(item.ttl).getTime()) {
    localStorage.removeItem(keyName)
    return null
  }

  // return data if not expired
  return item.value
}

export const getUserInfo = async () => {
  const token = getLocalStorage('access_token')
  if (!token) {
    return
  }

  let userInfo = localStorage.getItem('user_info')
  if (!userInfo) {
    // if no value exists associated with the key, return null
    await ajax.get('/v1/users/info').then((response) => {
      setLocalStorage('user_info', response.data.data, 60)
      setLocalStorage('user_id', response.data.data.userId, 24 * 60 * 60)
      return getLocalStorage('user_info')
    })
  }
}
